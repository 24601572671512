export enum ActionTypes {
  GET_BARBERS = 'GET_BARBERS',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  GET_PORTFOLIO = 'GET_PORTFOLIO',
  GET_PORTFOLIOS = 'GET_PORTFOLIOS',
  PUT_PORTFOLIO_IMAGE = 'PUT_PORTFOLIO_IMAGE',
  DELETE_PORTFOLIO = 'DELETE_PORTFOLIO',
  USER_LOGIN = 'USER_LOGIN',
  USER_LOGOUT = 'USER_LOGOUT',
  USER_SIGNUP = 'USER_SIGNUP',
  UPDATE_ACCOUNT = 'UPDATE_ACCOUNT',
  UPDATE_ACCOUNT_PASSWORD = 'UPDATE_ACCOUNT_PASSWORD',
  GET_ACCOUNT = 'GET_ACCOUNT',
  GET_CURRENT_ACCOUNT = 'GET_CURRENT_ACCOUNT',
  CREATE_PORTFOLIO_STYLE = 'CREATE_PORTFOLIO_STYLE',
  UPDATE_PORTFOLIO_STYLE = 'UPDATE_PORTFOLIO_STYLE',
  GET_MY_PORTFOLIOS = 'GET_MY_PORTFOLIOS',
  GET_PORTFOLIOS_BY_ID = 'GET_PORTFOLIOS_BY_ID',
  GET_CURRENT_ACCOUNT_PORTFOLIOS = 'GET_CURRENT_ACCOUNT_PORTFOLIOS',
  GET_PORTFOLIO_BY_ID = 'GET_PORTFOLIO_BY_ID',
  CHECK_USER_EMAIL = 'CHECK_USER_EMAIL',
  UPDATE_ERROR = 'UPDATE_ERROR',
  TOKEN_RENEWER = 'TOKEN_RENEWER',
  REMOVE_UPDATE_MULTI_IMAGE = 'REMOVE_UPDATE_MULTI_IMAGE',
  REMOVE_UPDATE_UPDATE_MULTI_IMAGE = 'REMOVE_UPDATE_UPDATE_MULTI_IMAGE',
  UPDATE_PORTFOLIO_MULTI_IMAGE = 'UPDATE_PORTFOLIO_MULTI_IMAGE',
  UPLOAD_AVATAR = 'UPLOAD_AVATAR',
  SOCKET_ONOPEN = 'SOCKET_ONOPEN',
  SOCKET_ONCLOSE = 'SOCKET_ONCLOSE',
  SOCKET_ONERROR = 'SOCKET_ONERROR',
  SOCKET_ONMESSAGE = 'SOCKET_ONMESSAGE',
  SOCKET_RECONNECT = 'SOCKET_RECONNECT',
  SOCKET_RECONNECT_ERROR = 'SOCKET_RECONNECT_ERROR',
  UPDATE_MESSAGE = 'UPDATE_MESSAGE',
  CONFIRM_EMAIL = 'CONFIRM_EMAIL',
  USER_REFERRAL_CODE_VERIFICATION = 'USER_REFERRAL_CODE_VERIFICATION',
  INVITE_USER = 'INVITE_USER',
  GET_INVITEES = 'GET_INVITEES',
  CANCEL_ACCOUNT = 'CANCEL_ACCOUNT',
  DELETE_INVITE_USER = 'DELETE_INVITE_USER',
  GET_SUBSCRIBERS = 'GET_SUBSCRIBERS',
  ALLOW_PORTFOLIO = 'ALLOW_PORTFOLIO',
  DELETE_ACCOUNT = 'DELETE_ACCOUNT',
  BLOCK_ACCOUNT = 'BLOCK_ACCOUNT',
  ADD_FAVORITES = 'ADD_FAVORITES',
  SEND_USER_EMAIL_RESET_PASSWORD = 'SEND_USER_EMAIL_RESET_PASSWORD',
  UPDATE_PASSWORD = 'UPDATE_PASSWORD',
  UPDATE_OPT_IN = 'UPDATE_OPT_IN',
  DELETE_AVATAR = 'DELETE_AVATAR',
  ALLOW_PORTFOLIOS = 'ALLOW_PORTFOLIOS',
}
