import { io } from "socket.io-client";
import { useAccountStore } from "./store/accounts";
import { UAParser } from 'ua-parser-js';
import { useCookieBannerStore } from "./store/CookieBannerStore";


const URL = import.meta.env.VITE_VUE_APP_YPONY_REALTIME_URL;

export const socket = io(URL)

socket.on("connect", async () => {

  const parser = new UAParser();
  socket.emit("browser_info", { browserInfo: parser.getResult(), userIP: useCookieBannerStore().user_ip })
});

socket.on("disconnect", (message) => {
  console.log('disconnect', message)
});

socket.on("message", (message) => {
  if (message === undefined) {
    return false
  }
  const parsedMessage = JSON.parse(message)
  const accountStore = useAccountStore()

  if (accountStore.current_account.attributes.account_id === parsedMessage.account_id) {
    accountStore.$reset()
    accountStore.GET_CURRENT_ACCOUNT({
      account_id: parsedMessage.account_id
    })
    accountStore.GET_CURRENT_ACCOUNT_PORTFOLIOS({
      account_id: parsedMessage.account_id
    })
  }

});
