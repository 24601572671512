<template>
  <main>
    <single-upload
      :url_link="currentAccount().attributes.avatar"
      :img_title="'Choose image'"
      :tab-index="tabIndex('avatar')"
    />

    <form class="form">
      <h6 class="mt-5 mb-1 text-left">
        About you
      </h6>

      <div class="my-3">
        <IInput
          :id="'first_name'"
          v-model.trim="currentAccount().attributes.first_name"
          :label="'First name'"
          :input-type="'text'"
          :placeholder="'First name*'"
          :help-text="''"
          :tab-index="tabIndex('first_name')"
          :is-required="true"
        />
      </div>

      <div class="my-3">
        <IInput
          :id="'last_name'"
          v-model.trim="currentAccount().attributes.last_name"
          :label="'Last name'"
          :input-type="'text'"
          :placeholder="'Last name*'"
          :help-text="''"
          :tab-index="tabIndex('last_name')"
          :is-required="true"
        />
      </div>
      <div class="my-3">
        <IInput
          :id="'company'"
          v-model.trim="currentAccount().attributes.company"
          :label="'Company'"
          :input-type="'text'"
          :placeholder="'Company*'"
          :help-text="''"
          :tab-index="tabIndex('company')"
          :is-required="true"
          :klass="[
            getError && getError.input_name == 'company' && getError.status,
          ]"
        />
      </div>
      <div class="my-3">
        <IInput
          :id="'Domain'"
          v-model.trim="currentAccount().attributes.domain"
          :label="'Domain URL'"
          :input-type="'text'"
          :placeholder="'Website (e.g. https://your-domain.com)*'"
          :help-text="''"
          :tab-index="tabIndex('domain')"
          :is-required="true"
          :klass="[
            getError && getError.input_name == 'company' && getError.status,
          ]"
        />
      </div>

      <div class="my-3">
        <IInput
          :id="'booking_link'"
          v-model.trim="currentAccount().attributes.booking_link"
          :label="'Booking URL'"
          :input-type="'text'"
          :placeholder="'Booking URL (e.g. https://your-domain.com)'"
          :help-text="''"
          :tab-index="tabIndex('booking_link')"
          :is-required="true"
        />
      </div>

      <div class="my-3">
        <IInput
          :id="'job_title'"
          v-model.trim="currentAccount().attributes.job_title"
          :label="'Job title'"
          :input-type="'text'"
          :placeholder="'Job title*'"
          :help-text="''"
          :tab-index="tabIndex('job_title')"
          :is-required="true"
        />
      </div>

      <div class="my-3">
        <IInput
          :id="'city'"
          v-model.trim="currentAccount().attributes.city"
          :label="'City'"
          :input-type="'text'"
          :placeholder="'City*'"
          :help-text="''"
          :tab-index="tabIndex('city')"
          :is-required="true"
        />
      </div>
      <div class="my-3">
        <IInput
          :id="'address'"
          v-model.trim="currentAccount().attributes.address"
          :label="'Full address'"
          :input-type="'text'"
          :placeholder="'Full address*'"
          :help-text="''"
          :tab-index="tabIndex('address')"
          :is-required="true"
        />
      </div>
      <div class="my-3">
        <IInput
          :id="'facebook'"
          v-model.trim="currentAccount().attributes.facebook"
          :label="'facebook'"
          :input-type="'text'"
          :placeholder="'Facebook username'"
          :help-text="''"
          :tab-index="tabIndex('facebook')"
          :is-required="true"
        />
      </div>
      <div class="my-3">
        <IInput
          :id="'Instagram'"
          v-model.trim="currentAccount().attributes.instagram"
          :label="'Instagram'"
          :input-type="'text'"
          :placeholder="'Instagram username'"
          :help-text="''"
          :tab-index="tabIndex('instagram')"
          :is-required="true"
        />
      </div>
      <div class="my-3">
        <IInput
          :id="'telegram'"
          v-model.trim="currentAccount().attributes.telegram"
          :label="'telegram'"
          :input-type="'text'"
          :placeholder="'Telegram username (without @)'"
          :help-text="''"
          :tab-index="tabIndex('telegram')"
          :is-required="true"
        />
      </div>
      <div class="my-3">
        <IInput
          :id="'telephone'"
          v-model.trim="currentAccount().attributes.telephone"
          :label="'telephone'"
          :input-type="'text'"
          :placeholder="'Phone (e.g. +49123456789)'"
          :help-text="''"
          :tab-index="tabIndex('telephone')"
          :is-required="true"
        />
      </div>

      <div class="my-3">
        <IInput
          :id="'whatsapp'"
          v-model.trim="currentAccount().attributes.whatsapp"
          :label="'whatsapp'"
          :input-type="'text'"
          :placeholder="'Whatsapp (e.g. +49123456789)'"
          :help-text="''"
          :tab-index="tabIndex('whatsapp')"
          :is-required="true"
        />
      </div>
      <div class="my-3">
        <ITextarea
          :id="'aboutyou'"
          v-model.trim="currentAccount().attributes.about_me"
          :rows="5"
          :tab-index="tabIndex('about_me')"
          :placeholder="'Add some personal words to introduce yourself...*'"
        />
      </div>

      <h6 class="mt-5 mb-1 text-left">
        User will find you by these #Tags
      </h6>
      <ul class="tag-cloud text-left">
        <li
          v-for="(tag, index) in currentAccount().attributes.tags"
          :key="index"
        >
          {{ tag }}
        </li>
      </ul>

      <h6 class="mt-5 mb-1 text-left">
        Your pricing range
      </h6>
      <div class="pricing-range d-flex flex-row column-gap-3">
        <div class="pricing-range--input-wrapper">
          <IInput
            :id="'price_min'"
            v-model="currentAccount().attributes.price_min"
            :label="'Minimum price'"
            :input-type="'number'"
            :placeholder="'Min.'"
            :tab-index="tabIndex('price_min')"
            :is-required="true"
          />
        </div>

        <div class="pricing-range--input-wrapper">
          <IInput
            :id="'price_min'"
            v-model="currentAccount().attributes.price_max"
            :label="'Maximum price'"
            :input-type="'number'"
            :placeholder="'Max.'"
            :tab-index="tabIndex('price_max')"
            :is-required="true"
          />
        </div>
      </div>
      <div class="align-button">
        <IButton
          :action="'success'"
          :tab-index="17"
          class="btn btn-primary mb-2 mt-5 my-md-5 mx-1"
          tabindex="3"
          @click.prevent="
            $emit('update:handleAccount', '', 'public_account', true)
          "
        >
          Save
        </IButton>

        <router-link
          v-if="currentAccount().attributes.company"
          :to="{
            query: {
              account_id: currentAccount().attributes.account_id,
            },
            path: `/${createSlug(currentAccount().attributes.company)}/me`,
          }"
          class="btn btn-primary mt-2 mb-5 my-md-5 mx-1"
        >
          View Profile
        </router-link>
      </div>
    </form>
  </main>
</template>

<script lang="ts">
import { Component, Vue, Inject, toNative } from 'vue-facing-decorator'
import type { AccountType } from '@/types'

import {
  Input as IInput,
  Button as IButton,
  Textarea as ITextarea,
  singleUpload,
} from '@/components'
import { useErrorStore } from '@/store/errorStore'
import { createSlug } from '@/helpers'

@Component({
  components: { IInput, IButton, ITextarea, singleUpload },
  emits: ['update:handleAccount'],
})
class PublicDetails extends Vue {
  @Inject
  currentAccount!: () => AccountType

  get getError() {
    return useErrorStore().getError
  }

  tabIndex(key: string) {
    const keys = Object.keys(this.currentAccount().attributes)

    return keys.indexOf(key)
  }
  createSlug(company: string) {
    return createSlug(company)
  }
}
export default toNative(PublicDetails)
</script>

<style lang="scss">
.about {
  .profile-gallery h6 {
    max-width: 68vw;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
.align-button {
  display: flex;
  gap: 0.25em 0.75em;
  text-wrap: nowrap;
  flex-flow: wrap;
  justify-content: center;
  flex-direction: row;
  margin: 0;
}
</style>
