<template>
  <div class="banner d-flex flex-column flex-sm-row column-gap-2">
    <div class="banner--text">
      <h4 class="m-0">
        Cookies
      </h4>
      <p class="mt-2">
        We use tracking cookies to understand how you use the product and help us
        improve it. Please accept cookies to help us improve.
      </p>
    </div>
    <div
      class="banner--buttons d-flex flex-align-center flex-column flex-sm-row justify-content-center column-gap-2"
    >
      <IButton
        :tab-index="1"
        class="btn btn-primary mt-0 mb-2 my-sm-0"
        @click="acceptCookies"
      >
        Accept cookies
      </IButton>

      <IButton
        :tab-index="2"
        class="btn btn-secondary m-0 my-sm-0"
        @click="declineCookies"
      >
        Decline cookies
      </IButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCookieBannerStore } from '@/store/CookieBannerStore'
const emit = defineEmits<(event: 'hideBanner') => void>()

const cookieBannerStore = useCookieBannerStore()
function acceptCookies() {
  cookieBannerStore.UPDATE_OPT_IN({ opt_in: true })
  emit('hideBanner')
}

function declineCookies() {
  cookieBannerStore.UPDATE_OPT_IN({ opt_in: false })
  emit('hideBanner')
}
</script>

<style scoped lang="scss">
</style>
