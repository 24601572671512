import { authClient } from '@/requests'
import type {
  AccountPasswordRequest,
  ApiResponse,
  AuthTokenDataResponse,
  PortfolioType,
} from '@/types'
import type { AxiosResponseHeaders } from 'axios'

/**
 * @class AccountDataService
 * @description Axios service provider for make API requests to Account model.
 */
class AccountDataService {
  /**
   * @method register
   * @description Method to register user with given name, email and password.
   * @param {IAccountRequest} account_data
   * @returns Promise
   */
  async updateAccount(accountData: IAccountRequest, accountId: string): Promise<ApiResponse> {
    return authClient.put(`/v1/users/accounts/${accountId}`, accountData)
  }

  async resetPassword(accountData: AccountPasswordRequest | undefined, email: string): Promise<ApiResponse> {
    const path = email !== '' ? '/v1/users/reset_passwords' : '/v1/users/passwords'
    return authClient.patch(path, { ...accountData, email })
  }

  async emailExist(email: string): Promise<ApiResponse> {
    return authClient.post('/v1/users/checkemail', { email: email })
  }
  async updatePassword(accountData: AccountPasswordRequest): Promise<ApiResponse> {
    return authClient.post('/v1/users/update_passwords', accountData)
  }
  // v1/users/update_passwords
  async getAccountByID(accountId: string): Promise<ApiResponse> {
    return authClient.get(`/v1/users/accounts/${accountId}`)
  }

  async uploadAvatar(data: FormData): Promise<ApiResponse> {
    return authClient.post('/v1/users/avatar', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }
  async createPortfolio(data: FormData): Promise<ApiResponse> {
    return authClient.post('/v1/users/portfolios', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  async updatePortfolio(data: FormData, style_id: string): Promise<ApiResponse> {
    return authClient.put(`/v1/users/portfolios/${style_id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  async updatePortfolioImage(data: never, encoded_token: string): Promise<ApiResponse> {
    return authClient.put(`/store_images/disk/${encoded_token}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  async confirmEmail(confirm_token: string): Promise<ApiResponse> {
    return authClient.get(`/v1/users/confirm_email?confirm_token=${confirm_token}`)
  }

  async inviteUser(email: string): Promise<ApiResponse> {
    return authClient.post('/v1/users/invite', email)
  }
  async deleteInviteUser(email: string): Promise<ApiResponse> {
    return authClient.post('/v1/users/cancel_invite', email)
  }

  async cancelAccount(): Promise<ApiResponse> {
    return authClient.delete('/v1/users/cancel_account')
  }

  async getInvitees(): Promise<ApiResponse> {
    return authClient.get('/v1/users/invitees')
  }

  async getMyPortfolios(): Promise<ApiResponse> {
    return authClient.get('/v1/users/my_portfolios')
  }

  async getPortfolio(style_id: string): Promise<ApiResponse> {
    return authClient.get(`/v1/users/portfolios/${style_id}`)
  }

  async deletePortfolio(style_id: string): Promise<ApiResponse> {
    return authClient.delete(`/v1/users/portfolios/${style_id}`)
  }
  async deleteAvatar(): Promise<ApiResponse> {
    return authClient.delete('/v1/users/delete_avatar')
  }

  async getAccountPortfolios(account_id: string): Promise<ApiResponse> {
    return authClient.get(`/v1/users/${account_id}/portfolios`)
  }
}

/**
 * @interface IAccountRequest
 * @description Public interface to describe request data for Account model.
 */

export interface IAccountRequest {
  payload: Record<string, unknown>
  first_name: string
  last_name: string
  company: string
  job_title: string
  city: string
  about_me: string
  price_min: string
  price_max: string
  user_id: number
  telephone: string
  telegram: string
  facebook: string
  address: string
  domain: string
  instagram: string
  whatsapp: string
  booking_link: string
}
export interface IAccountPasswordUpdateRequest {
  password: string
  password_confirmation: string
}
export interface IPortfolioStylesResponse {
  header: AxiosResponseHeaders
  data: PortfolioType
}

// Export Account data service.
export default new AccountDataService()
