<script setup lang="ts">
import { favicon, favicon2x, log, logoColor2x, logoY } from '@/helpers'
import { useImage } from '@vueuse/core'
import { onMounted, onUnmounted, ref } from 'vue'
const props = defineProps({
  videoUrl: {
    type: String,
    required: true,
  },
  // eslint-disable-next-line vue/require-prop-types
  videoType: {
    required: true,
  },
})
const imageOptions = ref({ src: props.videoUrl })
const { isLoading } = useImage(imageOptions, { delay: 1000 })
</script>

<template>
  <div v-if="isLoading" class="is-ready">
    <img :src="logoY" :srcset="logoY" alt="loader" class="loader-logo" />
    <div class="is-loader" />
  </div>
  <video
    v-else
    :src="videoUrl"
    :type="videoType"
    width="100%"
    autoplay
    muted
    playsinline
    loop
  />
</template>

<style lang="scss" scoped>
.text {
  margin-bottom: 0.7rem;
  font-size: 2rem;
  margin-left: 0.5rem;
}

.loader-logo,
.slideshow .swiper-slide .loader-logo {
  width: unset;
  height: 40%;
}

.is-ready {
  height: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  background-color: #0000;
  margin: auto;
}

.is-loader {
  width: 40px;

  margin-left: -4px;
  margin-bottom: -40%;
  aspect-ratio: 4;
  background: url('/src/assets/logo-dot.svg')
    0 / calc(100% / 3) 100% space;
  clip-path: inset(0 100% 0 0);
  animation: l1 900ms steps(4) infinite;
}

@keyframes l1 {
  to {
    clip-path: inset(0 -34% 0 0);
  }
}
</style>
