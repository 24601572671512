<template>
  <div>
    <CreateSingleUpload
      :image-title="imgTitle"
      :is-loading="accountStore.isLoading"
      :default-image="
        accountStore.current_account.attributes.avatar || defaultImage
      "
      @create-single="uploadCreateSingle"
      @delete-single="deleteCreateSingle"
    />
  </div>
</template>
<script lang="ts">
import { Component, Vue, Inject, toNative, Prop } from 'vue-facing-decorator'
import type { AccountType } from '@/types'
import CreateSingleUpload from './single-upload/createSingle.vue'
import iconUrl from '@/assets/placeholder-ratio_1-1.png'
import { useAccountStore } from '@/store/accounts'
import { useAuthTokenStore } from '@/store/authToken'
import { useErrorStore } from '@/store/errorStore'

@Component({
  components: { CreateSingleUpload },
  emits: ['update:handleAccount'],
})
class SingleUpload extends Vue {
  @Inject
  currentAccount!: () => AccountType

  @Prop({
    default: iconUrl,
  })
  defaultImage!: string
  @Prop({
    default: 'Choose image',
  })
  imgTitle!: string

  accountStore = useAccountStore()
  authTokenStore = useAuthTokenStore()

  uploadCreateSingle(file: string | Blob) {
    const formData = new FormData()
    formData.append('avatar', file)

    this.accountStore.UPLOAD_AVATAR({
      data: formData,
    })
  }
  deleteCreateSingle() {
    this.accountStore.DELETE_AVATAR()
  }
}
export default toNative(SingleUpload)
</script>
