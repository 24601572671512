import { defineStore } from 'pinia'
import {
  type AccountPasswordRequest,
  type AccountType,
  type ApiErrorResponse,
  type ApiResponse,
  Concrete,
  ReferralUsers,
} from '@/types/index'
import { ActionTypes } from '../action-types'
import { AccountDataService as Account, type AccountRequest } from '../../services/index'

import { state as initialState } from '@/store/state'
import { useAuthTokenStore } from '@/store/authToken'
import { useErrorStore } from '../errorStore'
import Bugsnag from '@bugsnag/js'
import {pick} from 'lodash'
const state = pick(initialState, [
  'current_account',
  'selected_tab',
  'create_single',
  'status',
  'statusText',
  'isRequestLoading',
  'isLoading',
  'message',
  'errors',
  'portfolios',
  'current_user',
  'referral_users',
])

export const useAccountStore = defineStore('accountStore', {
  state: () => ({ ...state }),
  getters: {
    getCurrentAccount({ current_account }): AccountType {
      return current_account
    },
    getSelectedTab({ selected_tab }) {
      return selected_tab
    },
  },

  actions: {
    authTokenStore: () => useAuthTokenStore(),
    updateSelectedTab(selected: number) {
      this.selected_tab = selected
    },
    createSingle(file: never) {
      this.current_account.attributes.avatar = file
    },
    async [ActionTypes.INVITE_USER]({ email }: { email: string }) {
      this.isLoading = true
      const promise: Promise<ApiResponse> = new Promise((resolve) => {
        const account = Account.inviteUser(email)

        resolve(account)
      })

      try {
        const { data, status, statusText } = await promise
        this.referral_users = data
        this.status = Number(status)
        this.statusText = statusText
        this.message = 'The email has been sent to the invitee.'
      } catch (error) {

           if (import.meta.env.NODE_ENV=== 'production') {
          Bugsnag.notify(error)
        }

        useErrorStore().UPDATE_ERROR(error)
      } finally {
        if (useErrorStore().errors.status !== 404) {
          this.isRequestLoading = false
          this.isLoading = false
        }
      }
    },
    async [ActionTypes.SEND_USER_EMAIL_RESET_PASSWORD]({ email }: { email: string }) {
      this.isLoading = true
      const promise: Promise<ApiResponse> = new Promise((resolve) => {
        const account = Account.resetPassword(undefined, email)

        resolve(account)
      })

      try {
        const { data, status, statusText } = await promise

        this.status = Number(status)
        this.statusText = statusText
        this.message = data.message
      } catch (error) {

           if (import.meta.env.NODE_ENV=== 'production') {
          Bugsnag.notify(error)
        }

        useErrorStore().UPDATE_ERROR(error)
      } finally {
        if (useErrorStore().errors.status !== 404) {
          this.isRequestLoading = false
          this.isLoading = false
        }
      }
    },
    async [ActionTypes.DELETE_INVITE_USER]({ email }: { email: string }) {
      this.isLoading = true
      const promise: Promise<ApiResponse> = new Promise((resolve) => {
        const account = Account.deleteInviteUser(email)

        resolve(account)
      })

      try {
        const { data, status, statusText } = await promise
        this.referral_users = data
        this.status = Number(status)
        this.statusText = statusText
        this.message = data.message
      } catch (error) {
         if (import.meta.env.NODE_ENV=== 'production') {
          Bugsnag.notify(error)
        }
        useErrorStore().UPDATE_ERROR(error)
      } finally {
        if (useErrorStore().errors.status !== 404) {
          this.isRequestLoading = false
          this.isLoading = false
        }
      }
    },
    async [ActionTypes.UPDATE_ACCOUNT]({
      account_data,
      account_id,
    }: {
      account_data: AccountRequest
      account_id: string
    }) {
      this.isLoading = true
      const promise: Promise<ApiResponse> = new Promise((resolve) => {
        const account = Account.updateAccount(account_data, account_id)

        resolve(account)
      })

      try {
        const {
          data,
          status,
          statusText,
        } = await promise

        this.current_account = data.account
        this.status = Number(status)
        this.statusText = statusText
      } catch (error) {
         if (import.meta.env.NODE_ENV=== 'production') {
          Bugsnag.notify(error)
        }
        useErrorStore().UPDATE_ERROR(error)
      } finally {
        if (useErrorStore().errors.status !== 404) {
          this.isRequestLoading = false
          this.isLoading = false
        }
      }
    },
    async [ActionTypes.CONFIRM_EMAIL]({ confirm_token }: { confirm_token: string }) {
      this.isLoading = true
      const promise: Promise<ApiResponse> = new Promise((resolve) => {
        const account = Account.confirmEmail(confirm_token)

        resolve(account)
      })

      try {
        const { data, status, statusText } = await promise

        this.message = data.message
        this.status = Number(status)
        this.statusText = statusText
      } catch (error: unknown) {
         if (import.meta.env.NODE_ENV=== 'production') {
          Bugsnag.notify(error)
        }
        useErrorStore().UPDATE_ERROR(error)
      } finally {
        if (useErrorStore().errors.status !== 404) {
          this.isRequestLoading = false
          this.isLoading = false
        }
      }
    },
    async [ActionTypes.GET_INVITEES]() {
      this.isLoading = true
      const promise: Promise<ApiResponse> = new Promise((resolve) => {
        const invitees = Account.getInvitees()

        resolve(invitees)
      })

      try {
        const { data, status, statusText } = await promise
        this.referral_users = data
        this.status = Number(status)
        this.statusText = statusText
      } catch (error) {
         if (import.meta.env.NODE_ENV=== 'production') {
          Bugsnag.notify(error)
        }
        useErrorStore().UPDATE_ERROR(error)
      } finally {
        if (useErrorStore().errors.status !== 404) {
          this.isRequestLoading = false
          this.isLoading = false
        }
      }
    },
    async [ActionTypes.CANCEL_ACCOUNT]() {
      this.isLoading = true
      const promise: Promise<ApiResponse> = new Promise((resolve) => {
        const account = Account.cancelAccount()

        resolve(account)
      })

      try {
        const { data, status, statusText } = await promise
        this.message = data.message
        this.status = Number(status)
        this.statusText = statusText
      } catch (error) {
         if (import.meta.env.NODE_ENV=== 'production') {
          Bugsnag.notify(error)
        }
        useErrorStore().UPDATE_ERROR(error)
      } finally {
        if (useErrorStore().errors.status !== 404) {
          this.isRequestLoading = false
          this.isLoading = false
        }
      }
    },
    async [ActionTypes.GET_CURRENT_ACCOUNT]({ account_id }: { account_id: string }) {
      this.isLoading = true
      const promise: Promise<ApiResponse> = new Promise((resolve) => {
        const account = Account.getAccountByID(account_id)

        resolve(account)
      })

      try {
        const {
          data: { data: account },
          status,
          statusText,
        } = await promise
        this.current_account = account
        this.status = Number(status)
        this.statusText = statusText
      } catch (error) {
         if (import.meta.env.NODE_ENV=== 'production') {
          Bugsnag.notify(error)
        }
        useErrorStore().UPDATE_ERROR(error)
      } finally {
        if (useErrorStore().errors.status !== 404) {
          this.isRequestLoading = false
          this.isLoading = false
        }
      }
    },
    async [ActionTypes.UPDATE_ACCOUNT_PASSWORD]({
      account_data,
      current_user_email = '',
    }: {
      account_data: AccountPasswordRequest
      current_user_email?: string
    }) {
      this.isLoading = true
      const promise: Promise<ApiResponse> = new Promise((resolve) => {
        const account = Account.resetPassword(account_data, current_user_email)
        resolve(account)
      })

      try {
        const { status, statusText, data } = await promise

        this.message = data.message
        this.status = Number(status)
        this.statusText = statusText
      } catch (error) {
         if (import.meta.env.NODE_ENV=== 'production') {
          Bugsnag.notify(error)
        }
        useErrorStore().UPDATE_ERROR(error)
      } finally {
        if (useErrorStore().errors.status !== 404) {
          this.isRequestLoading = false
          this.isLoading = false
        }
      }
    },
    async [ActionTypes.UPDATE_PASSWORD]({
      account_data,
    }: {
      account_data: AccountPasswordRequest
      current_user_email?: string
    }) {
      this.isLoading = true
      const promise: Promise<ApiResponse> = new Promise((resolve) => {
        const account = Account.updatePassword(account_data)
        resolve(account)
      })

      try {
        const { status, statusText, data } = await promise

        this.message = data.message
        this.status = Number(status)
        this.statusText = statusText
      } catch (error) {
         if (import.meta.env.NODE_ENV=== 'production') {
          Bugsnag.notify(error)
        }
        useErrorStore().UPDATE_ERROR(error)
      } finally {
        if (useErrorStore().errors.status !== 404) {
          this.isRequestLoading = false
          this.isLoading = false
        }
      }
    },
    async [ActionTypes.GET_CURRENT_ACCOUNT_PORTFOLIOS]({ account_id }: { account_id: string }) {
      this.isLoading = true
      const promise: Promise<ApiResponse> = new Promise((resolve) => {
        const portfolios = Account.getAccountPortfolios(account_id)

        resolve(portfolios)
      })

      try {
        const {
          data: { data: portfolios },
          status,
          statusText,
        } = await promise
        this.portfolios = portfolios
        this.status = Number(status)
        this.statusText = statusText
      } catch (error) {
         if (import.meta.env.NODE_ENV=== 'production') {
          Bugsnag.notify(error)
        }
        useErrorStore().UPDATE_ERROR(error)
      } finally {
        if (useErrorStore().errors.status !== 404) {
          this.isRequestLoading = false
          this.isLoading = false
        }
      }
    },
    async [ActionTypes.UPLOAD_AVATAR]({ data }: { data: FormData }) {
      this.isLoading = true
      const promise: Promise<ApiResponse> = new Promise((resolve) => {
        const avatar = Account.uploadAvatar(data)

        resolve(avatar)
      })

      try {
        const { data, status, statusText } = await promise
        this.GET_CURRENT_ACCOUNT({account_id: this.current_account.attributes.account_id})
        this.status = Number(status)
        this.statusText = statusText
        this.message = data.message
      } catch (error) {
         if (import.meta.env.NODE_ENV=== 'production') {
          Bugsnag.notify(error)
        }
        useErrorStore().UPDATE_ERROR(error)
      } finally {
        if (useErrorStore().errors.status !== 404) {
          this.isRequestLoading = false
          this.isLoading = false
        }
      }
    },
    async [ActionTypes.DELETE_AVATAR]() {
      this.isLoading = true
      const promise: Promise<ApiResponse> = new Promise((resolve) => {
        const avatar = Account.deleteAvatar()

        resolve(avatar)
      })

      try {
        const { data, status, statusText } = await promise

        this.current_account.attributes.avatar = ''
        this.status = Number(status)
        this.statusText = statusText
        this.message = data.message
      } catch (error) {
         if (import.meta.env.NODE_ENV=== 'production') {
          Bugsnag.notify(error)
        }
        useErrorStore().UPDATE_ERROR(error)
      } finally {
        if (useErrorStore().errors.status !== 404) {
          this.isRequestLoading = false
          this.isLoading = false
        }
      }
    },

    async [ActionTypes.CHECK_USER_EMAIL]({ email }: { email: string }) {
      this.isLoading = true
      const promise: Promise<ApiResponse> = new Promise((resolve) => {
        const existedEmail = Account.emailExist(email)

        resolve(existedEmail)
      })

      try {
        const { data, status, statusText } = await promise
        this.current_user.email = data.email
        this.message = data.message
        this.status = Number(status)
        this.statusText = statusText
      } catch (error) {
         if (import.meta.env.NODE_ENV=== 'production') {
          Bugsnag.notify(error)
        }
        useErrorStore().UPDATE_ERROR(error)
      } finally {
        if (useErrorStore().errors.status !== 404) {
          this.isRequestLoading = false
          this.isLoading = false
        }
      }
    },
  },
  persist: false,
})

// if (import.meta.hot) {
//   import.meta.hot.accept(acceptHMRUpdate(useAccountStore, import.meta.hot))
// }
