import { authClient } from '@/requests'
import { ApiResponse } from '@/types'

/**
 * @class PortfolioDataService
 * @description Axios service provider for make API requests to Portfolio model.
 */
class PortfolioDataService {
  /**
   * @method register
   * @description Method to register user with given name, email and password.
   * @param {IAccountRequest} account_data
   * @returns Promise
   */


  async getPortfolios(): Promise<ApiResponse> {
    return authClient.get('/v1/users/portfolios')
  }

  async getPortfolioByID(portfolio_id: string): Promise<ApiResponse> {
    return authClient.get(`/v1/users/portfolios/${portfolio_id}`)
  }
  // async deletePortfolio(style_id: string, access_token: string, signal: AbortSignal): Promise<any> {
  //   return authClient.delete(`/v1/users/portfolios/${style_id}`, {
  //     headers: {
  //       Authorization: `Bearer ${access_token}`,
  //     },
  //     signal,
  //   })
  // }
}

/**
 * @interface IAccountRequest
 * @description Public interface to describe request data for Portfolio model.
 */

// export interface IAccountRequest {
//   avatar: string
//   payload: Record<string, unknown>
//   first_name: string
//   last_name: string
//   company: string
//   job_title: string
//   city: string
//   about_me: string
//   price_min: string
//   price_max: string
//   user_id: number
// }

// export interface IAccountResponse {
//   [x: string]: any
//   data: {
//     [x: string]: any
//     id: string
//     type: string
//     attributes: {
//       avatar: string
//       payload: Record<string, unknown>
//       first_name: string
//       last_name: string
//       company: string
//       job_title: string
//       city: string
//       about_me: string
//       price_min: string
//       price_max: string
//       tags: string[]
//       status: number
//       email: string
//     }
//   }
// }

// export interface IAccountPasswordUpdateRequest {
//   password: string
//   password_confirmation: string
// }
// export interface IPortfolioStylesResponse {
//   header: AxiosResponse
//   data: PortfolioType

// {
//   id: string
//   type: string
//   attributes: {
//     gender: string
//     color: string
//     style: string
//     images: string[]
//     plan_name: {
//       name: string
//       label: string
//       isSelected: boolean
//     }[]
//     tags: string[]
//     payload: {
//       preview: string
//       file?: File
//       id: number
//       show_in_gallery: boolean
//       encoded_token: string
//       filename: string
//       image_to_delete: boolean
//     }[]
//   }
// }[]
// }

// Export Portfolio data service.
export default new PortfolioDataService()
