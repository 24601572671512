import './style.scss'
import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
import { createPinia, setMapStoreSuffix } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import VueGtag from 'vue-gtag'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import BugsnagPerformance from '@bugsnag/browser-performance'

let bugsnagVue =undefined
if (import.meta.env.NODE_ENV=== 'production') {
Bugsnag.start({
  apiKey: '0e9a96641ea079d65eb2ab4a74066216',
  plugins: [new BugsnagPluginVue()],
  notifyReleaseStages: ['production'],
})

  BugsnagPerformance.start({ apiKey: '0e9a96641ea079d65eb2ab4a74066216' })
  bugsnagVue = Bugsnag.getPlugin('vue')
}

// import Toast, { PluginOptions, POSITION } from 'vue-toastification'
import Toast, { type PluginOptions, POSITION } from 'vue-toastification'

import 'vue-toastification/dist/index.css'
import { socket } from './socket'

// Set options for plugins.
const toastOptions: PluginOptions = {
  maxToasts: 5,
  newestOnTop: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  hideProgressBar: false,
  closeButton: false,
  filterBeforeCreate: (toast: any, toasts: any) => {
    if (toasts.filter((t: any) => t.type === toast.type).length !== 0) {
      // Returning false discards the toast.
      return false
    }
    return toast
  },
}

setMapStoreSuffix('')
const storePinia = createPinia()
storePinia.use(piniaPluginPersistedstate)

createApp(App)
  .use(
    VueGtag,
    {
      bootstrap: false,
      appName: 'Y.Pony',
      pageTrackerScreenviewEnabled: true,
      config: { id: 'G-6WPP4ZV4P0' },
    },
    router
  )
  .use(Toast, toastOptions)
  .use(storePinia)
  .use(router)
  .use(bugsnagVue)
  .mount('#app')

socket
